<template>
    <div class="form-group form-group-sm mb-0" v-show="visivel != 'false' && visivel != false" :style="tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : ''">

        <label v-if="titulo && (typeof showTitulo === 'undefined' || (typeof showTitulo !== 'undefined' && showTitulo === true) )" :for="campo" class="mct-label mb-0" :style="[coluna > 0 ? `min-width: ${coluna}px; max-width: ${coluna}px;` : '', tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : '']">{{ titulo }}
            <small class="mct-obg" v-if="obrigatorio == 'true' || obrigatorio == true"> * </small>
            <i style="font-size: 14px;" class="fa fa-info-circle text-info" aria-hidden="true" v-if="typeof obs != 'undefined' && obs !== '' && obs !== null"                 
                data-toggle="tooltip" data-placement="right" :title="obs"></i>
        </label>        
        
        <div :id="'ig_'+campo" class="input-group mt-0" :class="tipo == 'colorpicker' ? 'colorpicker-component' : ''">
            
            <v-select v-if="tipo == 'select' && !dataCombo" :id="'sm_'+campo" class="form-control m-0 p-0" :options="opcoes" v-model="valor[campo]" :reduce="opcao => opcao[origemChave]" :value="origemChave" :label="origemRetorno"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex" 
                 ></v-select>

            <!-- SELECT -->        
            <select v-if="tipo == 'select' && dataCombo" class="form-control" :id="campo" :placeholder="titulo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                v-model="valor[campo]"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex"
                >
                <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
                <option v-for="(registro,index) in dataCombo" :key="index" :value="origemChave ? registro[origemChave] : registro.value ? registro.value : index">{{ origemRetorno ? registro[origemRetorno] : registro.text }} <small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-muted">[{{ registro[origemChave] }}]</small> </option>
            </select>

            <!-- SELECT - BUSCA BASE DADOS--> 
            <select v-if="tipo == 'select' && !dataCombo" class="selectpicker show-tick form-control" :id="'sm_'+campo" :placeholder="titulo" title="Selecione um Item"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"        
                v-model="valor[campo]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex"
                data-live-search="true" :ref="'sm_'+campo" data-style="mct-select" data-size="5" data-container="body"
                @click.prevent.stop="onReflesh()"
                @change="changeSelect"
                :data-show-subtext="typeof origemRetornoSec != 'undefined' && origemRetornoSec != null ? true : false"                             
                >
                <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
                <option v-for="(registro,index) in opcoes" :key="index" :value="registro[origemChave]"
                    :data-subtext="typeof origemRetornoSec != 'undefined' && origemRetornoSec != null ? registro[origemRetornoSec] : ''">
                    <span v-if="origemRetorno.split(',') != -1"><span v-for="(reg,i) in origemRetorno.split(',')" :key="i"> {{ registro[reg] }} </span></span>
                    <span v-else> {{ registro[origemRetorno] }} </span>
                </option>
            </select>

            <!-- MULTI-SELECT - BUSCA BASE DADOS-->        
            <multiselect v-if="(tipo == 'selectmult') && !dataCombo" class="form-control" :id="campo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true" 
                :tabindex="Number(tabindex) ? Number(tabindex) : null"
                :multiple="tipo == 'selectmult'"
                :close-on-select="tipo == 'selectmult' ? false : true"
                :clear-on-select="tipo == 'selectmult' ? false : true"
                v-model="valor[campo]"
                :options="opcoes"
                :value="origemChave"
                :label="origemRetorno"
                :track-by="origemChave"
                :return="origemChave"

                placeholder="Adicionar um novo Item"
                select-label="Clique ou Enter para selecionar!"
                deselect-label="Clique ou Enter para remover."
                selected-label='Selecionado'
                >
            </multiselect>
            
            <!-- BUSCA MODAL -->
            <input type="text" class="form-control" :id="campo" :placeholder="titulo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': typeof errors != 'undefined' && errors[campo]}]" 
                :style="typeof alinhamentoTexto != 'undefined' ? `text-align: ${alinhamentoTexto} ` : ''"    
                v-model="valor[campo]"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true" 
                v-if="tipo == 'search' || tipo == 'search-input'"
                @keyup.enter="getDescricao(valor[campo],origem,origemRetorno)"
                @blur="getDescricao(valor[campo],origem,origemRetorno)"
                :tabindex="tabindex"
            >
            <div class="input-group-append" v-if="tipo == 'search' || tipo == 'search-input'">
                <button class="btn btn-secondary input-group-text" type="button" id="btnPesquisa" 
                    @click="evtModal($event)" :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')" 
                    tabindex="-1" alt="Abrir listagem."><i class="fa fa-search" aria-hidden="true"></i></button>
            </div> 
            

            <div class="input-group-append form-control col-auto"  v-if="tipo == 'search'" :id="`dsc${campo}`" tabindex="-1" readonly>
               {{ retornoDescricao }}
            </div> 

            <!-- BOTAO OPEN CADASTRO -->
            <!--&& (abrirCadastro == true || abrirCadastro == 'true')-->
            <div class="input-group-append" v-if="(tipo == 'select' || tipo == 'search') && !dataCombo && (modo == 'novo' || modo == 'editar') && (typeof showBotoes === 'undefined' || (typeof showBotoes !== 'undefined' && showBotoes === true))" @click="abrirPopup($props)">
                <a class="btn btn-sm btn-success input-group-text" id="btnAbrirCadastro" href="#" title="Adicionar Novo Cadastro."
                    tabindex="-1"
                ><i class="fa fa-plus" aria-hidden="true"></i></a>
            </div>

            <!-- REFRESH LISTAGEM -->
            <div class="input-group-append" v-if="(tipo == 'select') && !dataCombo && (modo == 'novo' || modo == 'editar') && (typeof showBotoes === 'undefined' || (typeof showBotoes !== 'undefined' && showBotoes === true))" @click.prevent.stop="getLista()">
                <a class="btn btn-sm btn-info input-group-text" id="btnRefreshLista" href="#" title="Atualizar Listagem."
                    tabindex="-1"
                ><i class="fa fa-refresh" aria-hidden="true"></i></a>
            </div>

            <!-- LEGENDA -->
            <slot name="legenda">
                
            </slot> 
            <div class="input-group-append" v-if="typeof legenda != 'undefined' && legenda != ''">
                <span class="input-group-text" >{{legenda}}</span>
            </div> 

            <!-- OBRIGATORIO -->
            <div class="invalid-feedback" v-for="(item,idx) in errors[campo]" :key="idx">
                {{item}} 
            </div> 
        </div>    

        <!-- MODAL -->
        <mct-modal v-if="tipo == 'search' || tipo == 'search-input'" :id="modalId" :titulo="this.titulo ? 'Pesquisar: ' + this.titulo : this.telaListaTitulo ? 'Pesquisar: ' + this.telaListaTitulo : '' ">
            <component :is="this.telaLista ? this.telaLista : 'MctDataViewer'" :source="`${ this.origem.replace(/_/g, '')}/lista`" :titulo="this.titulo ? this.titulo : this.telaListaTitulo ? this.telaListaTitulo : '' " showModal="true" @setDescricao="setDescricao"></component>
        </mct-modal>
    </div>   
</template>

<script>
import Vue from 'vue'
import axios from '@/http'

import MctModal from './MctModal.vue'
import MctDataViewer from './MctDataViewer.vue'

import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
    name: 'MctInputSelect',
    props: ['coluna','linhas','tipo','campo','transforme','alinhamentoTexto','valor','titulo','subtitulo','tamanho','obrigatorio','modo',
            'origem','origemChave','origemRetorno', 'origemRetornoSec', 'origemData','origemDataJson','origemDataCSV','origemLinha','origemChaveComposta', 'origemFiltro', 'gravaRetorno',
            'errors','tabindex','telaLista','telaListaTitulo','visivel','chave', 'abrirCadastro', 'legenda', 'obs', 'showBotoes', 'showTitulo'] ,
    components: {
        MctModal,
        MctDataViewer,
        Multiselect/*: Multiselect.default*/,
        /*** COMPONENTES DE BUSCA ***/
    },
    data () {        
        return {
            retornoDescricao: '',
            modalId: '1',
            opcoes: [],
            dataCombo: null,
        }
    },
    computed: {        
        ...mapState('Login',['Entidade','Exercicio','Competencia','EntidadeOrc','baseURL'])
    },
    methods: {
        async getLista () {
            var url = this.origem
            let Parms = '?' 
            if (this.origem != '' && typeof this.origem != 'undefined' && typeof this.origem != 'null' && this.origem != null){
                if (this.origem.indexOf("/") != -1){
                    url = this.origem.replace(/_/g, '')
                }else{
                    url = this.origem.replace(/_/g, '')+'/combo'                    
                }
                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    if (typeof this.origemFiltro == 'object'){
                        for (var index in this.origemFiltro) {
                            if (Parms != '?') { Parms += '&'}
                            Parms += index + '='
                            //Parms += typeof this.valor[this.origemFiltro[index]] != 'undefined' ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] 
                            Parms += this.origemFiltro[index] in this.valor ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] !== null && typeof this.origemFiltro[index] !== 'undefined' ? this.origemFiltro[index] : 0
                        }
                    }else{
                        Parms += this.origemFiltro
                    }
                }
                url += Parms 
                await axios.get(url)
                    .then(response => {
                        this.opcoes = response.data.data
                        this.setDataLista(this.opcoes) 
                    })
                    .catch(response => {
                        //console.log(response)
                    })
            }
        },
        async getDescricao (busca, origem, retorno) {
            let Parms = '?'
            let filtros = ''
            if ((busca != '' && typeof busca != 'undefined' && typeof busca != 'null' && busca != null) &&
                (origem != '' && typeof origem != 'undefined' && typeof origem != 'null' && origem != null)){     

                if (this.origemChaveComposta === true || this.origemChaveComposta === 'true'){
                    Parms = Parms + this.origemChave +'='+ busca
                }else{
                    Parms = '/' + busca
                }

                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    for (var index in this.origemFiltro) {
                        if (Parms != '?') { Parms += '&'}
                        Parms += index +'='+ this.origemFiltro[index] in this.valor ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] !== null ? this.origemFiltro[index] : 0
                    }
                }

                var url = origem.replace(/_/g, '')+'/editar'+Parms 
                //console.log(url)

                await axios.get(url)
                .then(response => {
                    this.retornoDescricao = response.data.data[retorno].toUpperCase()
                    this.setDescricao(response.data.data)
                })
                .catch(response => {
                    if (typeof response.response.status !== 'undefined' && response.response.status == 404) {
                        this.retornoDescricao = 'REGISTRO NÃO LOCALIZADO'
                        this.setDataRetorno(response.response.status)
                    }
                })
            }
        }, 
        setDescricao (retorno) {
            this.valor[this.campo] = retorno[this.origemChave]             
            //$(`#dsc${this.campo}`).html(retorno[this.origemRetorno])
            if (this.tipo != 'search-input'){
                if (typeof retorno[this.origemRetorno] === 'string'){
                    this.retornoDescricao = retorno[this.origemRetorno].toUpperCase()
                }else{
                    this.retornoDescricao = retorno[this.origemRetorno]
                }
                if (typeof this.gravaRetorno !== 'undefined' && this.gravaRetorno){
                    this.valor[this.origemRetorno] = retorno[this.origemRetorno]
                }
                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    for (var index in this.origemFiltro) {
                        this.valor[this.origemFiltro[index]] = retorno[index]
                    }
                }                
            }
            this.setDataRetorno(retorno)
            if (typeof this.origemData != 'undefined' && typeof this.origemData != 'null' && this.origemData != null){
                for (var index in retorno) {
                        this.origemData[index] = retorno[index]
                    }
            }
            $('#MctModal'+this.modalId).modal('hide')
        },                   
        evtModal (ev) {
            $('#MctModal'+this.modalId).modal('show')
        },
        setDataRetorno (data) {
            this.$emit('setDataRetorno',data)
        },
        setDataLista (data) {
            this.$emit('setDataLista',data)            
        },
        csvJSON(csv){
            var lines=csv.split("\n")
            var result = []
            var headers=lines[0].split(";")
            for(var i=1;i<lines.length;i++){
                var obj = {}
                var currentline=lines[i].split(";")
                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j]
                }
                result.push(obj)
            }
            return result
        },        
        abrirPopup(data){
            this.$store.dispatch('Popup/ABRIR_POPUP', [data, true]);
        },
        onKeyUp() {
            this.$emit('onKeyUp')    
        },
        onChange(e) {
            if (this.tipo == 'colorpicker'){
                this.valor[this.campo] = e.target.value
            }
            this.$emit('onChange')
        },
        onReflesh() {
            this.getLista()
            if(this.tipo == 'select'){
                $(this.$refs['sm_'+this.campo]).selectpicker('refresh')
                $(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();
            }            
        },
        changeSelect(){
            //console.log('teste')             
        },
        loadModal(component, source, titulo) {
            this.$modal.open({
                id: source,
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: {
                    component: component,
                    source: `${source}/novo`, 
                    titulo: titulo, 
                    modo: 'novo'
                },
            })
        },
    },
    created () {
        if (((this.tipo == 'select') || (this.tipo == 'selectmult')) && (!this.origemData)){
            this.getLista()  
        }/*else 
        if (this.tipo == 'search'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }else 
        if (this.tipo == 'search-input'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }
        
        if((this.origemDataJson !== null) && (typeof this.origemDataJson !== 'undefined')){
          http.get('src/components/tabsInternas/'+this.origemDataJson).then(response => {
            this.dataCombo = response.data
          });
        }else{
            this.dataCombo = this.origemData
        }

        if((this.origemDataCSV !== null) && (typeof this.origemDataCSV !== 'undefined')){
          http.get('src/components/tabsInternas/'+this.origemDataCSV).then(response => {
            var csv = response.data
            var csvJson = this.csvJSON(csv)
             this.dataCombo = csvJson
          });
        }*/        

    },
    mounted () {
        //$(this.$refs['sm_'+this.campo]).selectpicker()
        if(this.tipo == 'select'){
            //$(this.$refs['sm_'+this.campo]).selectpicker('refresh')
            //$(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();                       
            //const $selectpicker = $(this.$el).find('.selectpicker')
            //$selectpicker.selectpicker().on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => this.setDataRetorno(this.opcoes[clickedIndex-1]))            
        }

        $('[data-toggle="tooltip"]').tooltip();            
    },
    updated () {
        if(this.tipo == 'select'){
            /*var vm = this
            $(this.$refs['sm_'+this.campo]).selectpicker()
            $(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();    
            $(this.$refs['sm_'+this.campo]).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                if (typeof clickedIndex !== 'undefined'){
                    if (typeof vm.gravaRetorno !== 'undefined' && vm.gravaRetorno){
                        if (clickedIndex === 1 && (this.obrigatorio !== 'true' && this.obrigatorio !== true)){
                            vm.valor[vm.origemRetorno] = null    
                        }else{
                            vm.valor[vm.origemRetorno] = vm.opcoes[clickedIndex - (this.obrigatorio !== 'true' && this.obrigatorio !== true ? 2 : 1)][vm.origemRetorno]                            
                        }
                    }
                    if (clickedIndex === 1 && (this.obrigatorio !== 'true' && this.obrigatorio !== true)){
                    }else{
                        vm.setDataRetorno(vm.opcoes[clickedIndex - (this.obrigatorio !== 'true' && this.obrigatorio !== true ? 2 : 1)])
                    }
                }
            });*/            

        }
	},
    watch: {
        'opcoes' () {
            if(this.tipo == 'select'){
                //$(this.$refs['sm_'+this.campo]).selectpicker('refresh')
                $(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();
            }
        },
        'valor' () {                
            /*if (this.tipo == 'search'){
                this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                this.modalId = Math.floor((Math.random() * 99999999) + 1)
            }else 
            if (this.tipo == 'search-input'){
                this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                this.modalId = Math.floor((Math.random() * 99999999) + 1)
            }*/
        } ,
        'origemData' () {
            if((this.origemDataJson === null) || (typeof this.origemDataJson === 'undefined')){
                this.dataCombo = this.origemData
            }
        },
        origemFiltro: {
            handler: function (val, oldVal) {
                var changed = Object.keys(val).some( function( prop ) {
                    return val[prop] !== oldVal[prop];
                })
                if (changed){
                    this.getLista()
                }
            },
            deep: true
        }                       
    }          
}
</script>


<style scoped>



</style>
